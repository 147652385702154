import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ImageBar from "../ImageBar"
import TeamWork from "../../../images/team_work.svg"
const LandigPageOne = () => {
  return (
    <div className="">
      <div className="my-10 flex flex-col items-center justify-between xl:my-0 xl:flex-row">
        <div className="w-full xl:my-20 xl:w-[calc(100%-40rem)]">
          <h1 className=" text-4xl leading-snug md:text-6xl">
            Vítejte na stránkách{" "}
            <span className="bg-gradient-to-r from-[#FF6665] to-[#F5A604] bg-clip-text  font-extrabold text-transparent">
              Základní školy Anafra
            </span>
          </h1>
          <p className="my-10 text-xl leading-relaxed">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit
            eveniet iste aspernatur amet officiis, ut non dolorum eaque deleniti
            voluptas quis quod labore doloribus harum debitis! Rem cumque
            inventore debitis.
          </p>
        </div>
        <div>
          <TeamWork className="h-full w-full xl:w-[35rem]" />
        </div>
      </div>
    </div>
  )
}

export default LandigPageOne
