import * as React from "react"
import LastEvents from "../components/Events/LastEvents"
import UpcomingEvents from "../components/Events/FutureEvents"
import { Layout } from "../components/Layout/Layout"
import QuickLinks from "../components/QucikLinks/QuickLinks"
import LandigPageOne from "../components/Layout/LandingPages/LandigPageOne"

// markup
const IndexPage = () => {
  return (
    <>
      <Layout activePage={{ title: "", slug: "/" }}>
        <div className="content">
          <LandigPageOne />
          <div className="grid-cols-3 gap-6 xl:grid">
            <div className="col-span-2">
              <LastEvents />
            </div>
            <div className="col-span-1">
              <UpcomingEvents />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
